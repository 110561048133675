export const milkTeas = [
  {
    "title": "chocolate milk",
    "price": "$6",
    "description": "Chocolate mixed with honey shaken with Assam tea & your choice of dairy. Garnished with Oreo cookie pieces, chocolate syrup, and topped with tapioca bubbles.",
  },
  {
    "title": "brown sugar",
    "price": "$6",
    "description": "Dark brown sugar shaken with Assam and your choice of dairy. Garnished with dark brown sugar, and topped with tapioca bubbles.",
  },
  {
    "title": "thai tea",
    "price": "$6.5",
    "description": "Star anise, cinnamon and vanilla flavors sweetened with honey, Assam tea, and your choice of dairy. Topped with tapioca bubbles.",
  },
  {
    "title": "green melon",
    "price": "$6",
    "description": "Sweet green honeydew paired with honey, green tea, and your choice of dairy. Topped with strawberry bubbles.",
  },
  {
    "title": "purple taro",
    "price": "$6",
    "description": "Japanese purple yam sweetened with honey, green tea, and your choice of dairy. Topped with tapioca bubbles.",
  },
  {
    "title": "strawberry cream",
    "price": "$5.5",
    "description": "Sweet strawberry, green tea, and honey combined with your choice of dairy. Topped with tapioca bubbles.",
  },
]
