import './App.scss';
import React, { useEffect } from 'react';
import styled from 'styled-components'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  withRouter
} from "react-router-dom";

// Utilities
// import { colors } from "./utilities"
// import { queryBelow } from "../../utilities/mediaQuery"

// Components
import SVGSprite from './components/SVGSprite'
import GlobalHeader from './components/GlobalHeader'
import Header from './components/Header'
import Footer from './components/Footer'

// Menu Pages
import Home from './pages/Home'
import Menu from './pages/Menu'

import ClevelandBowls from './pages/ClevelandBowls'
import ClevelandMenu from './pages/ClevelandMenu'
import ClevelandSnacks from './pages/ClevelandSnacks'
import ClevelandTacos from './pages/ClevelandTacos'
import ClevelandTeas from './pages/ClevelandTeas'

import DallasBowls from './pages/DallasBowls'
import DallasMenu from './pages/DallasMenu'
import DallasSnacks from './pages/DallasSnacks'
import DallasTacos from './pages/DallasTacos'
import DallasTeas from './pages/DallasTeas'

import DenverMenu from './pages/DenverMenu'
import DenverBowls from './pages/DenverBowls'
import DenverSnacks from './pages/DenverSnacks'
import DenverTeas from './pages/DenverTeas'
import DenverTacos from './pages/DenverTacos'

import Bowls from './pages/Bowls'
import Tacos from './pages/Tacos'
import Snacks from './pages/Snacks'
import Teas from './pages/Teas'

const AppContainer = styled.div`
  min-height: 100vh;
`

// Scroll to top
function _ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return props.children
}

const ScrollToTop = withRouter(_ScrollToTop)

// App
function App() {
  return (
    <AppContainer>
      <SVGSprite />
      <Router>
        <ScrollToTop>
          <GlobalHeader />
          <Header />
          <Switch>

            {/* Cleveland */}
            <Route path="/cleveland/menu/">
              <ClevelandMenu />
            </Route>
            <Route path="/cleveland/bowls">
              <ClevelandBowls />
            </Route>
            <Route path="/cleveland/tacos">
              <ClevelandTacos />
            </Route>
            <Route path="/cleveland/snacks">
              <ClevelandSnacks />
            </Route>
            <Route path="/cleveland/bubble-tea">
              <ClevelandTeas />
            </Route>

            {/* Dallas */}
            <Route path="/dallas/menu/">
              <DallasMenu />
            </Route>
            <Route path="/dallas/bowls">
              <DallasBowls />
            </Route>
            <Route path="/dallas/tacos">
              <DallasTacos />
            </Route>
            <Route path="/dallas/snacks">
              <DallasSnacks />
            </Route>
            <Route path="/dallas/bubble-tea">
              <DallasTeas />
            </Route>

            {/* Denver */}
            <Route path="/denver/menu/">
              <DenverMenu />
            </Route>
            <Route path="/denver/bowls">
              <DenverBowls />
            </Route>
            <Route path="/denver/tacos">
              <DenverTacos />
            </Route>
            <Route path="/denver/snacks">
              <DenverSnacks />
            </Route>
            <Route path="/denver/bubble-tea">
              <DenverTeas />
            </Route>

            {/* Toledo */}
            <Route path="/bowls">
              <Bowls />
            </Route>
            <Route path="/tacos">
              <Tacos />
            </Route>
            <Route path="/snacks">
              <Snacks />
            </Route>
            <Route path="/teas">
              <Teas />
            </Route>
            <Route path="/menu">
              <Menu />
            </Route>

            {/* Home Page */}
            <Route path="/">
              <Home />
            </Route>
          </Switch>

          <Footer />
        </ScrollToTop>
      </Router>
    </AppContainer>
  );
}

export default App;
