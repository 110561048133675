import React, { Component } from 'react'
import styled from 'styled-components'
// import { Link } from "react-router-dom";

// Utilities
import { colors } from "../../utilities"
// import { queryBelow } from "../../utilities/mediaQuery"

// Components
import Title from '../../components/Title'
import SocialNav from '../../components/SocialNav'
import AppStoreNav from '../../components/AppStoreNav'

// Styled Components
const FooterContainer = styled.div`
  background-color: ${colors.background};
  border-top: 1px solid ${colors.border};
  margin-top: 3em;
  padding: 5em 0;
  position: relative;
  z-index: 3;

  &:before {
    content: "";
    position: absolute;
    top: calc(-3em - 1px);
    left: 0;
    width: 100%;
    height: 3em;
    background-image: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,1));
  }
`

const FooterWrap = styled.div`
  align-items: baseline;
  justify-content: flex-start;
  flex-direction: column;
`

const FooterSection = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 2em 2em 0;
`

const Copyright = styled.p`
  font-size: 0.875em;
  margin-bottom: 2em;

  a {
    color: ${colors.typeLight};
    text-decoration: underline;
  }
`

// Helpers
let newDate = new Date()
let year = newDate.getFullYear()

// Footer
class Footer extends Component {
  render() {
    // const { number, title } = this.props;

    return(
      <FooterContainer>
        <FooterWrap className="wrap">

          <FooterSection>
            <Title text="Order from our app" />
            <AppStoreNav />
          </FooterSection>

          <FooterSection>
            <Title text="Follow us" />
            <SocialNav />
          </FooterSection>

          <Copyright>
            &copy; {year} Balance Syndicated LLC. The mark BALANCE, the Balance Triangle B mark, and the Balance Pan-Asian Grille logo are registered in the U.S. Patent and Trademark Office. The marks BALANCE, BALANCE PAN-ASIAN GRILLE, the Balance Triangle B mark, the Balance Pan-Asian Grille logo, and all other marks and associated logos are owned by Balance Syndicated LLC. Site by <a href="https://electricfun.io" title="Site by Electric Fun • Meaningful digital experiences.">Electric Fun</a>.
          </Copyright>

        </FooterWrap>
      </FooterContainer>
    );
  }
}

export default Footer;
