export const butterflyTeas = [

  {
    // "image": "https://balancegrille.s3.us-east-1.amazonaws.com/menu-images/snacks/edemame.png",
    "title": "b.lychee",
    "price": "$5",
    "description": "Blue raspberry and lychee with a splash of lemonade layered under caffeine-free butterfly tea! Topped with lychee bubbles. Served with ice. Shake before drinking!",
  },
  {
    // "image": "https://balancegrille.s3.us-east-1.amazonaws.com/menu-images/snacks/edemame.png",
    "title": "blueberry lavender lemonade",
    "price": "$5",
    "description": "Blueberry and lavender with a splash of lemonade layered under caffeine-free butterfly tea! Topped with blueberry bubbles. Served with ice. Shake before drinking!",
  },
  {
    // "image": "https://balancegrille.s3.us-east-1.amazonaws.com/menu-images/snacks/edemame.png",
    "title": "flower power",
    "price": "$5",
    "description": "Lavender and house-made simple syrup and oat milk layered under caffeine-free butterfly tea! Topped with tapioca bubbles. Served with ice. Shake before drinking!",
  },
  {
    // "image": "https://balancegrille.s3.us-east-1.amazonaws.com/menu-images/snacks/edemame.png",
    "title": "eye of the tiger",
    "price": "$5",
    "description": "Passion fruit and pomegranate with a splash of lemonade layered under caffeine-free butterfly Tea! Topped with pomegranate bubbles. Served with ice. Shake before drinking!",
  },
  // {
  //   // "image": "https://balancegrille.s3.us-east-1.amazonaws.com/menu-images/snacks/edemame.png",
  //   "title": "love equally",
  //   "price": "$5",
  //   "description": "Lemonade and tea with refreshing mango and strawberry flavors, topped with kiwi bubbles. Shake before drinking!",
  // },
  // {
  //   // "image": "https://balancegrille.s3.us-east-1.amazonaws.com/menu-images/snacks/edemame.png",
  //   "title": "new glass city",
  //   "price": "$5",
  //   "description": "Freshly brewed tea with grape and raspberry flavors, served with pomegranate bubbles. Shake before drinking!",
  // },
  {
    // "image": "https://balancegrille.s3.us-east-1.amazonaws.com/menu-images/snacks/edemame.png",
    "title": "sour patch",
    "price": "$5",
    "description": "House-made lemonade and freshly brewed caffeine-free butterfly tea! Topped with strawberry bubbles. Served with ice. Shake before drinking!",
  },
  // {
  //   "title": "full bloom",
  //   "price": "$5",
  //   "description": "Pomegranate and lychee with a splash of lemonade layered under caffeine-free butterfly tea! Topped with lychee bubbles. Served with ice. Shake before drinking!",
  // },

]
