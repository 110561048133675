import React, { Component } from 'react'
import { Helmet } from 'react-helmet'

// Data
import { milkTeas } from "../../data/milk-teas-denver";
import { icedTeas } from "../../data/iced-teas-denver";
import { frozenTeas } from "../../data/frozen-teas-denver";
// import { summerLemonadeTeas } from "../../data/summer-lemonade-denver";
import { butterflyTeas } from "../../data/butterfly-teas-denver";
// import { bubbleBrewTeas } from "../../data/bubble-brew-teas-denver";
// import { fallTeas2022 } from "../../data/fall-teas-2022-denver";
// import { fallTeas2023 } from "../../data/fall-teas-2023";
import { flightFuel } from "../../data/flight-fuel";

// Components
import BackLink from '../../components/BackLink'
import SubHeader from '../../components/SubHeader'
import MenuSection from '../../components/MenuSection'
import MenuItems from '../../components/MenuItems'
import MenuItem from '../../components/MenuItem'
import StepTitle from '../../components/StepTitle'

// Teas
class Teas extends Component {
  render() {
    return(
      <>
        <Helmet>
          <title>Bubble Tea - Denver Menu | Balance Pan-Asian Grille</title>
          <meta name="description" content="Hand-shaken and sealed. Fresh brewed organic tea, unique flavors, and bright colors! Milk teas, bubble teas, and more. Dairy-free options." />
        </Helmet>

        <MenuSection>
          <div className="wrap">
            <StepTitle
              title="Denver"
            />
          </div>
          <SubHeader
            title="bubble tea"
            // calloutText="Make any tea a large for +$1"
          />

          <div className="wrap">
            <StepTitle
              number="1"
              title="Flight Fuel"
            />
          </div>
          <MenuItems className="columns">
            {
              flightFuel.map((menuItem, key) => {
                return (
                  <MenuItem
                    key={key}
                    image={menuItem.image}
                    title={menuItem.title}
                    price={menuItem.price}
                    flavorTags={menuItem.flavorTags}
                    description={[menuItem.description, " ", <em>{menuItem.descriptionNotes}</em>]}
                    dietary={menuItem.dietary}
                    nutrition={menuItem.nutrition}
                  />
                );
              })
            }
          </MenuItems>

          <div className="wrap">
            <StepTitle
              number="2"
              title="Milk Teas"
            />
            <p>Choose <strong>organic milk</strong> or <strong>oat milk</strong> for +$1.</p>
          </div>
          <MenuItems className="columns">
            {
              milkTeas.map((menuItem, key) => {
                return (
                  <MenuItem
                    key={key}
                    image={menuItem.image}
                    title={menuItem.title}
                    price={menuItem.price}
                    flavorTags={menuItem.flavorTags}
                    description={[menuItem.description, " ", <em>{menuItem.descriptionNotes}</em>]}
                    dietary={menuItem.dietary}
                    nutrition={menuItem.nutrition}
                  />
                );
              })
            }
          </MenuItems>

          <div className="wrap">
            <StepTitle
              number="3"
              title="Iced Teas"
            />

            <p>Substitute <strong>butterfly tea</strong> for <strong>+$0.50</strong> to make it <strong>caffeine free</strong>!</p>
          </div>
          <MenuItems className="columns">
            {
              icedTeas.map((menuItem, key) => {
                return (
                  <MenuItem
                    key={key}
                    image={menuItem.image}
                    title={menuItem.title}
                    price={menuItem.price}
                    flavorTags={menuItem.flavorTags}
                    description={[menuItem.description, " ", <em>{menuItem.descriptionNotes}</em>]}
                    dietary={menuItem.dietary}
                    nutrition={menuItem.nutrition}
                  />
                );
              })
            }
					</MenuItems>

          <div className="wrap">
            <StepTitle
              number="4"
              title="Frozen Teas"
            />
            <p><strong>Only available for purchase in-store</strong> (for a limited time!)</p>
          </div>
          <MenuItems className="columns">
            {
              frozenTeas.map((menuItem, key) => {
                return (
                  <MenuItem
                    key={key}
                    image={menuItem.image}
                    title={menuItem.title}
                    price={menuItem.price}
                    flavorTags={menuItem.flavorTags}
                    description={[menuItem.description, " ", <em>{menuItem.descriptionNotes}</em>]}
                    dietary={menuItem.dietary}
                    nutrition={menuItem.nutrition}
                  />
                );
              })
            }
          </MenuItems>

          {/* <div className="wrap">
            <StepTitle
              number="4"
              title="Summer Lemonade"
            />
          </div>
          <MenuItems className="columns">
            {
              summerLemonadeTeas.map((menuItem, key) => {
                return (
                  <MenuItem
                    key={key}
                    image={menuItem.image}
                    title={menuItem.title}
                    price={menuItem.price}
                    flavorTags={menuItem.flavorTags}
                    description={[menuItem.description, " ", <em>{menuItem.descriptionNotes}</em>]}
                    dietary={menuItem.dietary}
                    nutrition={menuItem.nutrition}
                  />
                );
              })
            }
          </MenuItems> */}

          <div className="wrap">
            <StepTitle
              number="5"
              title="Butterfly Teas"
            />
          </div>
          <MenuItems className="columns">
            {
              butterflyTeas.map((menuItem, key) => {
                return (
                  <MenuItem
                    key={key}
                    image={menuItem.image}
                    title={menuItem.title}
                    price={menuItem.price}
                    flavorTags={menuItem.flavorTags}
                    description={[menuItem.description, " ", <em>{menuItem.descriptionNotes}</em>]}
                    dietary={menuItem.dietary}
                    nutrition={menuItem.nutrition}
                  />
                );
              })
            }
					</MenuItems>

          {/* <div className="wrap">
            <StepTitle
              number="6"
              title="Bubble Brew"
            />
          </div>
          <MenuItems className="columns">
            {
              bubbleBrewTeas.map((menuItem, key) => {
                return (
                  <MenuItem
                    key={key}
                    image={menuItem.image}
                    title={menuItem.title}
                    price={menuItem.price}
                    flavorTags={menuItem.flavorTags}
                    description={[menuItem.description, " ", <em>{menuItem.descriptionNotes}</em>]}
                    dietary={menuItem.dietary}
                    nutrition={menuItem.nutrition}
                  />
                );
              })
            }
					</MenuItems> */}

        </MenuSection>
        <BackLink url="/denver/menu"/>
      </>
    );
  }
}

export default Teas;
