import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
// import { Link } from "react-router-dom";

// Utilities
// import { colors } from "../../utilities"
// import { queryBelow } from "../../utilities/mediaQuery"

// Components
import StepTitle from '../../components/StepTitle'
import NavItem from '../../components/NavItem'

// Styled Components
const HomeContainer = styled.section`
  z-index: 9;
`

const Nav = styled.nav`
  display: flex;
  flex-direction: column;
`

// Home
class Home extends Component {
  render() {
    return(
      <>
        <Helmet>
          <title>Denver Menu | Balance Pan-Asian Grille</title>
          <meta name="description" content="A hip spot for clean Asian food. Serving up snacks, build-a-bowls, tacos, teas, and seasonal specials." />
        </Helmet>
        <HomeContainer>
          <div className="wrap">
            <StepTitle
              title="Denver Menu"
            />
            <Nav>
              <NavItem url="/denver/bowls">bowls</NavItem>
              <NavItem url="/denver/tacos">tacos</NavItem>
              <NavItem url="/denver/snacks">snacks</NavItem>
              <NavItem url="/denver/bubble-tea">teas</NavItem>
            </Nav>
          </div>
        </HomeContainer>
      </>
    );
  }
}

export default Home;
