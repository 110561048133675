export const bowls = [

  {
    "image": "https://balancegrille.s3.amazonaws.com/menu-images/bowls/green-curry-bowl.webp",
    "title": "green curry",
    "price": "$12",
    "flavorTags": [
      { "tag": "Herbacious" },
      { "tag": "Savory" },
      { "tag": "Spicy 3/5" },
    ],
    "description": "This classic Thai curry bowl is packed with herbs, spices, and heat. It is served with red bell peppers, peapods, seasonal sprouts, crushed peanuts and cilantro.",
    "dietary": [
      { "type": "vegan", "title": "Vegan Option", "subtitle": "Choose Tofu or Lentils for a protein." },
      { "type": "gluten-free", "title": "Gluten-Friendly Option", "subtitle": "Choose Fresh Greens or White / Brown Rice." },
    ],
    "nutrition": [
      { "value": "300", "unit": "Calories" },
      { "value": "6g", "unit": "Protein" },
      { "value": "18g", "unit": "Total Carbohydrate" },
      { "value": "24g", "unit": "Total Fat" },
    ]
  },

  {
    "image": "https://balancegrille.s3.us-east-1.amazonaws.com/menu-images/bowls/budda.png",
    "title": "budda",
    "price": "$12",
    "flavorTags": [
      { "tag": "Spicy 3/5" },
      { "tag": "Savory" },
      { "tag": "Aromatic" }
    ],
    "description": "Spicy Indian inspired butter sauce served with chickpeas, red onions, tomato, cilantro, and seasonal sprouts.",
    "descriptionNotes": "Contains dairy.",
    "dietary": [
      { "type": "vegetarian", "title": "Vegetarian Option", "subtitle": "Choose Tofu or Lentils for a protein." },
      { "type": "gluten-free", "title": "Gluten-Friendly Option", "subtitle": "Choose Fresh Greens or White / Brown Rice." }
    ],
    "nutrition": [
      { "value": "400", "unit": "Calories" },
      { "value": "5g", "unit": "Protein" },
      { "value": "18g", "unit": "Total Carbohydrate" },
      { "value": "36g", "unit": "Total Fat" },
    ]
  },

  {
    "image": "https://balancegrille.s3.us-east-1.amazonaws.com/menu-images/bowls/four-star.png",
    "title": "four-star",
    "price": "$12",
    "flavorTags": [
      { "tag": "Garlicky" },
      { "tag": "Tangy" },
      { "tag": "Sweet" }
    ],
    "description": "A tangy, garlicky General Tso’s sauce served with broccoli, carrots, and seasonal sprouts.",
    "descriptionNotes": "",
    "dietary": [
      { "type": "vegan", "title": "Vegan Option", "subtitle": "Choose Tofu or Lentils for a protein." }
    ],
    "nutrition": [
      { "value": "150", "unit": "Calories" },
      { "value": "4g", "unit": "Protein" },
      { "value": "30g", "unit": "Total Carbohydrate" },
      { "value": "1g", "unit": "Total Fat" },
    ]
  },

  {
    "image": "https://balancegrille.s3.us-east-1.amazonaws.com/menu-images/bowls/wiseman.png",
    "title": "wiseman",
    "price": "$12",
    "flavorTags": [
      { "tag": "Spicy 2/5" },
      { "tag": "Sweet" },
      { "tag": "Savory" }
    ],
    "description": "Customer favorite! A simple szechuan sauce served with broccoli, carrots, corn, seasonal sprouts, and kale.",
    "dietary": [
      { "type": "vegan", "title": "Vegan Option", "subtitle": "Choose Tofu or Lentils for a protein." }
    ],
    "nutrition": [
      { "value": "230", "unit": "Calories" },
      { "value": "6g", "unit": "Protein" },
      { "value": "51g", "unit": "Total Carbohydrate" },
      { "value": "1.5g", "unit": "Total Fat" },
    ]
  },

  {
    "image": "https://balancegrille.s3.us-east-1.amazonaws.com/menu-images/bowls/thai-guy.png",
    "title": "thai guy",
    "price": "$12",
    "flavorTags": [
      { "tag": "Spicy 3/5" },
      { "tag": "Creamy" },
      { "tag": "Nutty" }
    ],
    "description": "Thai-style peanut sauce served with carrots, seasonal hot peppers, seasonal sprouts, crushed peanuts, peapods, and cilantro. ",
    "descriptionNotes": "Contains Nuts.",
    "dietary": [
      { "type": "vegan", "title": "Vegan Option", "subtitle": "Choose Tofu or Lentils for a protein." },
      { "type": "gluten-free", "title": "Gluten-Friendly Option", "subtitle": "Choose Fresh Greens, White / Brown Rice" }
    ],
    "nutrition": [
      { "value": "370", "unit": "Calories" },
      { "value": "13g", "unit": "Protein" },
      { "value": "47g", "unit": "Total Carbohydrate" },
      { "value": "17g", "unit": "Total Fat" },
    ]
  },

  {
    "image": "https://balancegrille.s3.us-east-1.amazonaws.com/menu-images/bowls/teriyaki.png",
    "title": "teriyaki",
    "price": "$12",
    "flavorTags": [
      { "tag": "Sweet" },
      { "tag": "Savory" },
      { "tag": "Salty" }
    ],
    "description": "Classic Japanese teriyaki Sauce served with broccoli, carrots, red peppers, daikon radish, and red onions.",
    "dietary": [
      { "type": "vegan", "title": "Vegan Option", "subtitle": "Choose Tofu or Lentils for a protein." }
    ],
    "nutrition": [
      { "value": "180", "unit": "Calories" },
      { "value": "6g", "unit": "Protein" },
      { "value": "39g", "unit": "Total Carbohydrate" },
      { "value": "0g", "unit": "Total Fat" },
    ]
  },

  {
    "image": "https://balancegrille.s3.amazonaws.com/menu-images/bowls/Korean-Bibimbap.webp",
    "title": "korean bibimbap",
    "price": "$12",
    "flavorTags": [
      { "tag": "Spicy 2/5" },
      { "tag": "Tangy" },
      { "tag": "Sweet" }
    ],
    "description": "A classic Korean gochujang sauce with seasonal potatoes, daikon radish, kimchi pickles, red cabbage and seasonal sprouts. If you aren’t vegan, add a 7-min egg (+$2) for the full experience.",
    "dietary": [
      { "type": "vegan", "title": "Vegan Option", "subtitle": "Choose Tofu or Lentils for a protein." },
    ],
    "nutrition": [
      { "value": "360", "unit": "Calories" },
      { "value": "4g", "unit": "Protein" },
      { "value": "82g", "unit": "Total Carbohydrate" },
      { "value": "3g", "unit": "Total Fat" },
    ]
  },

  {
    "image": "https://balancegrille.s3.us-east-1.amazonaws.com/menu-images/bowls/kids-bowl.png",
    "title": "kids bowl",
    "price": "$8",
    "description": "Choose a protein and a starch option (found below), then pair with your choice of the following vegetables and sauce.",
    "additionalDescription": [
      { "title": "Vegetables", "text": "Choose broccoli, carrots, red peppers, corn, kale, or peapods." },
      { "title": "Sauces", "text": "Choose from teriyaki or four-star." },
    ]
  },

]
