export const butterflyTeas = [
  {
    "title": "b.lychee",
    "price": "$5",
    "description": "Blue raspberry and lychee with a splash of lemonade layered under caffeine-free butterfly tea! Topped with lychee bubbles. Served with ice. Shake before drinking!",
  },
  {
    "title": "blueberry lavender lemonade",
    "price": "$5",
    "description": "Blueberry and lavender with a splash of lemonade layered under caffeine-free butterfly tea! Topped with blueberry bubbles. Served with ice. Shake before drinking!",
  },
  {
    "title": "flower power",
    "price": "$5",
    "description": "Lavender and house-made simple syrup and oat milk layered under caffeine-free butterfly tea! Topped with tapioca bubbles. Served with ice. Shake before drinking!",
  },
  {
    "title": "eye of the tiger",
    "price": "$5",
    "description": "Passion fruit and pomegranate with a splash of lemonade layered under caffeine-free butterfly Tea! Topped with pomegranate bubbles. Served with ice. Shake before drinking!",
  },
  {
    "title": "sour patch",
    "price": "$5",
    "description": "House-made lemonade and freshly brewed caffeine-free butterfly tea! Topped with strawberry bubbles. Served with ice. Shake before drinking!",
  },
  {
    "title": "full bloom",
    "price": "$5",
    "description": "Pomegranate and lychee with a splash of lemonade layered under caffeine-free butterfly tea! Topped with lychee bubbles. Served with ice. Shake before drinking!",
  },
]
