export const icedTeas = [
  {
    "title": "just peachy",
    "price": "$4.5",
    "description": "A fan favorite! Juicy peach flavor shaken with ice & green tea, and topped with strawberry bubbles.",
  },
  {
    "title": "raspberry bliss",
    "price": "$4.5",
    "description": "Refreshing & fruity raspberry flavor shaken with ice & green tea, and topped with kiwi bubbles.",
  },
  {
    "title": "pineapple express",
    "price": "$5",
    "description": "Groovy pineapple & sweet strawberry flavors shaken with ice & green tea, and topped with passion fruit bubbles.",
  },
  {
    "title": "passion fruit",
    "price": "$4.5",
    "description": "Exotic & tangy passion fruit flavor shaken with ice & Assam tea, and topped with sweet strawberry bubbles.",
  },
  {
    "title": "the whatever",
    "price": "$5",
    "description": "Our take on tropical fruit punch! Our signature blend of five flavors shaken with ice & green tea, and topped with rainbow bubbles.",
  },
  {
    "title": "half & half",
    "price": "$5",
    "description": "Our take on a classic! House-made lemonade and simple syrup shaken with ice & Assam tea, and topped with pomegranate bubbles.",
  },
]
