export const icedTeas = [

  // {
  //   // "image": "https://balancegrille.s3.us-east-1.amazonaws.com/menu-images/snacks/edemame.png",
  //   "title": "strawberry tea",
  //   "price": "$4.5",
  //   "description": "A perfect addition to any order! Sweet strawberry shaken with ice & green tea, and topped with passion fruit bubbles.",
  // },
  {
    // "image": "https://balancegrille.s3.us-east-1.amazonaws.com/menu-images/snacks/edemame.png",
    "title": "just peachy",
    "price": "$4.5",
    "description": "A fan favorite! Juicy peach flavor shaken with ice & green tea, and topped with strawberry bubbles.",
  },
  {
    // "image": "https://balancegrille.s3.us-east-1.amazonaws.com/menu-images/snacks/edemame.png",
    "title": "raspberry bliss",
    "price": "$4.5",
    "description": "Refreshing & fruity raspberry flavor shaken with ice & green tea, and topped with kiwi bubbles.",
  },
  // {
  //   // "image": "https://balancegrille.s3.us-east-1.amazonaws.com/menu-images/snacks/edemame.png",
  //   "title": "mango blitz",
  //   "price": "$3.5",
  //   "description": "Sweet mango green tea with blueberry bubbles.",
  // },
  // {
  //   // "image": "https://balancegrille.s3.us-east-1.amazonaws.com/menu-images/snacks/edemame.png",
  //   "title": "green apple",
  //   "price": "$3.5",
  //   "description": "Tart green apple tea paired with sweet blueberry juice bubbles.",
  // },
  {
    // "image": "https://balancegrille.s3.us-east-1.amazonaws.com/menu-images/snacks/edemame.png",
    "title": "pineapple express",
    "price": "$5",
    "description": "Groovy pineapple & sweet strawberry flavors shaken with ice & green tea, and topped with passion fruit bubbles.",
  },
  {
    // "image": "https://balancegrille.s3.us-east-1.amazonaws.com/menu-images/snacks/edemame.png",
    "title": "passion fruit",
    "price": "$4.5",
    "description": "Exotic & tangy passion fruit flavor shaken with ice & Assam tea, and topped with sweet strawberry bubbles.",
  },
  {
    // "image": "https://balancegrille.s3.us-east-1.amazonaws.com/menu-images/snacks/edemame.png",
    "title": "the whatever",
    "price": "$5",
    "description": "Our take on tropical fruit punch! Our signature blend of five flavors shaken with ice & green tea, and topped with rainbow bubbles.",
  },
  // {
  //   // "image": "https://balancegrille.s3.us-east-1.amazonaws.com/menu-images/snacks/edemame.png",
  //   "title": "darkberry",
  //   "price": "$4",
  //   "description": "Our blend of six flavors served with a rainbow of fruity bubbles.",
  // },
  {
    // "image": "https://balancegrille.s3.us-east-1.amazonaws.com/menu-images/snacks/edemame.png",
    "title": "half & half",
    "price": "$5",
    "description": "Our take on a classic! House-made lemonade and simple syrup shaken with ice & Assam tea, and topped with pomegranate bubbles.",
  },
  // {
  //   // "image": "https://balancegrille.s3.us-east-1.amazonaws.com/menu-images/snacks/edemame.png",
  //   "title": "sour grapes",
  //   "price": "$4",
  //   "description": "A classic combination of sour apple and grape flavors layered over Lychee bubbles.",
  // },
  // {
  //   // "image": "https://balancegrille.s3.us-east-1.amazonaws.com/menu-images/snacks/edemame.png",
  //   "title": "plain tea",
  //   "price": "$4",
  //   "description": "Choose between our freshly brewed Assam, Green, and caffeine- free Butterfly tea. Add sweetener/bubbles for a splash of sweetness!",
  // },
  // {
  //   // "image": "https://balancegrille.s3.us-east-1.amazonaws.com/menu-images/snacks/edemame.png",
  //   "title": "custom iced tea",
  //   "price": "$5",
  //   "description": "Pick up to two current flavors! Choose between Green, Assam, or Butterfly tea. Made with your choice of sweetener and bubbles.",
  // },

]
