export const snacks = [

  {
    "image": "https://balancegrille.s3.amazonaws.com/menu-images/snacks/bangbang-nachos.png",
    "title": "bangbang nachos",
    "price": "$6",
    "flavorTags": [
      { "tag": "Citrusy" },
      { "tag": "Salty" },
      { "tag": "Spicy 5/5" },
    ],
    "description": "Inspired by the Indian street food scene, this snack is served with housemade crispy flour chips, seasonal potatoes, chickpeas, tomatoes, red onions, and cilantro. BangBang sauce and yogurt is served on the side.",
    // "dietary": [
    //   { "type": "vegan", "title": "Vegan" },
    //   { "type": "gluten-free", "title": "Gluten Free", }
    // ],
    "nutrition": [
      { "value": "520", "unit": "Calories" },
      { "value": "9g", "unit": "Protein" },
      { "value": "56g", "unit": "Total Carbohydrate" },
      { "value": "29g", "unit": "Total Fat" },
    ]
  },

  {
    // "image": "https://balancegrille.s3.amazonaws.com/menu-images/snacks/balance-snack-edamame.webp",
    "image": "https://balancegrille.s3.amazonaws.com/menu-images/snacks/Tajin-Tango-Edamame.webp",
    "title": "edamame",
    "price": "$5",
    "flavorTags": [
      { "tag": "Nutty" },
      { "tag": "Salty" },
      { "tag": "Citrusy" },
    ],
    "description": "Imported baby soybeans in the pod, briefly steamed and served either with our original sea salt recipe, or our citrus tango mix for an upcharge ($.50).",
    "dietary": [
      { "type": "vegan", "title": "Vegan" },
      { "type": "gluten-free", "title": "Gluten Free", }
    ],
    "nutrition": [
      { "value": "350", "unit": "Calories" },
      { "value": "31g", "unit": "Protein" },
      { "value": "28g", "unit": "Total Carbohydrate" },
      { "value": "15g", "unit": "Total Fat" },
    ]
  },

  {
    "image": "https://balancegrille.s3.us-east-1.amazonaws.com/menu-images/snacks/balance-snacks-creamy-wontons.png",
    "title": "creamy wontons",
    "price": "$4.5",
    "flavorTags": [
      { "tag": "Creamy" },
      { "tag": "Crispy" },
      { "tag": "Tart" }
    ],
    "description": "Handmade cream cheese and vegetable wontons served with our signature sweet & sour sauce. ",
    "descriptionNotes": "Contains dairy.",
    "dietary": [
      { "type": "vegetarian", "title": "Vegetarian" },
    ],
    "nutrition": [
      { "value": "530", "unit": "Calories" },
      { "value": "8g", "unit": "Protein" },
      { "value": "39g", "unit": "Total Carbohydrate" },
      { "value": "38g", "unit": "Total Fat" },
    ]
  },

  {
    "image": "https://balancegrille.s3.us-east-1.amazonaws.com/menu-images/snacks/citrus-brussels.png",
    "title": "citrus brussels",
    "price": "$5.5",
    "flavorTags": [
      { "tag": "Sweet" },
      { "tag": "Citrusy" },
      { "tag": "Tangy" }
    ],
    "description": "Split brussels sprouts wok-fired in a unique orange glaze. Perfectly balanced flavors in every bite!",
    "dietary": [
      { "type": "vegan", "title": "Vegan" },
      { "type": "gluten-free", "title": "Gluten Free" }
    ],
    "nutrition": [
      { "value": "450", "unit": "Calories" },
      { "value": "7g", "unit": "Protein" },
      { "value": "50g", "unit": "Total Carbohydrate" },
      { "value": "27g", "unit": "Total Fat" },
    ]
  },

  {
    "image": "https://balancegrille.s3.us-east-1.amazonaws.com/menu-images/snacks/five-spice-poppers.png",
    "title": "five spice poppers",
    "price": "$5.5",
    "flavorTags": [
      { "tag": "Sweet" },
      { "tag": "Savory" },
      { "tag": "Aromatic" }
    ],
    "description": "Fresh strips of juicy chicken thigh hand battered, fried, and spun in our signature five spice sauce to order. Served on a bed of red cabbage and garnished with fresh scallions.",
    // "descriptionNotes": "",
    "nutrition": [
      { "value": "750", "unit": "Calories" },
      { "value": "30g", "unit": "Protein" },
      { "value": "69g", "unit": "Total Carbohydrate" },
      { "value": "39g", "unit": "Total Fat" },
    ]
  },

]
