import React, { Component } from 'react'
import styled, { css } from 'styled-components'

// Utilities
import { colors } from "../../utilities"
import { queryBelow } from "../../utilities/mediaQuery"

// Data
import { bowlsProtein } from "../../data/bowls-protein-denver";
import { bowlsStarch } from "../../data/bowls-starch-denver";
import { vegetables } from "../../data/vegetables-denver";
import { garnishes } from "../../data/garnishes-denver";

// Components
import StepTitle from '../../components/StepTitle'
import ColumnItem from '../../components/ColumnItem'
import Ingredient from '../../components/Ingredient'

// Styled Components
const BowlsInstructionsContainer = styled.section`
  display: inline-block;
  width: 100%;
  margin-bottom: 2em;
  padding: 4em 0;

  h2 {
    font-size: 3.6em;
    color: rgba(255,255,255,0);
    -webkit-text-stroke: 1.5px ${colors.foreground};
    line-height: 1;
    margin-bottom: 2rem;
  }

  p {
    &.final-p {
      margin-bottom: 3rem;
    }
  }
`

const Step = styled.div`
  display: inline-block;
  width: 100%;
  margin-bottom: 1em;
`

const StepContent = styled.div`
  display: inline-block;
  margin-bottom: 1em;
  width: 100%;

  &.columns {
    columns: 2;

    ${queryBelow("bigPhone", css`
      columns: 1;
    `)}
  }
`

const ColumnList = styled(StepContent)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  ${queryBelow("bigPhone", css`
    flex-direction: column;
  `)}
`

// const Legend = styled.p`
//   margin-top: 2em;
//   font-size: 0.875em;

//   svg {
//     height: 1em;
//     width: 1em;

//     &, use {
//       fill: ${colors.health};
//     }
//   }
// `

class BowlsInstructions extends Component {
  render() {
    return(
      <BowlsInstructionsContainer>
        <div className="wrap">
          <h2>customize your bowl</h2>
          <Step>
            <StepTitle
              number="1"
              title="Choose Bowl"
            />
            <StepContent>
              <p>
                Choose a <strong>base bowl</strong> from above.
              </p>
            </StepContent>
          </Step>
          <Step>
            <StepTitle
              number="2"
              title="Choose Protein"
            />
            <StepContent>
              <p>
              Every protein option we serve is high grade, <strong>antibiotic free</strong>, <strong>Halal</strong>, and <strong>gluten friendly</strong>.
              </p>
            </StepContent>
            <ColumnList>
              {
                bowlsProtein.map((protein, key) => <ColumnItem key={protein.key} title={protein.title} priceModifier={protein.priceModifier} nutrition={protein.nutrition} /> )
              }
            </ColumnList>
          </Step>
          <Step>
            <StepTitle
              number="3"
              title="Choose Starch"
            />
            <ColumnList>
              {
                bowlsStarch.map((starch, key) => <ColumnItem key={starch.key} title={starch.title} priceModifier={starch.priceModifier} nutrition={starch.nutrition} /> )
              }
            </ColumnList>
          </Step>

          <h2>build your own bowl</h2>
          <p className="final-p">Choose a <strong>protein</strong> and a <strong>starch</strong> from above, then choose up to 5 <strong>vegetables / garnishes</strong> listed below (up to 10 for an extra charge). Add any <strong>balance sauce</strong> (even taco sauces) to round out your own bowl. Custom bowls start at $11.</p>
          <Step>
            <StepTitle
              title="Steamed Vegetables"
            />
            <StepContent className="columns">
              {
                vegetables.map((vegetables, key) => <Ingredient key={vegetables.key} title={vegetables.title} /> )
              }
            </StepContent>
          </Step>
          <Step>
            <StepTitle
              title="Garnishes"
            />
            <StepContent className="columns">
              {
                garnishes.map((garnishes, key) => <Ingredient key={garnishes.key} title={garnishes.title} source={garnishes.source} /> )
              }
            </StepContent>
          </Step>

        </div>
      </BowlsInstructionsContainer>
    );
  }
}

export default BowlsInstructions;
